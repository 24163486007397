.ReactTags__remove {
    border: none;
    margin-left: 5px;
}

.tag-wrapper {
    font-size: 16px;
    margin-right: 5px;
}

.ReactTags__tagInputField {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}